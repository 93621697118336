/** @jsx jsx */
import { Fragment } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { isLive, isCompleted } from '../../utils/utils';
import { Page, SEO, Container, Button, Share } from '../../components';
import { MainNavigation } from '../../compositions';
import {
  Content,
  HighlightHeading,
  Date,
  Figure,
  Intro,
  Description,
  MetaBox,
  MetaBoxSection,
  MetaTitle,
  SponsorList,
  SponsorItem,
  SponsorImg,
  PresenterList,
  PresenterItem,
  PresenterLink,
  PresenterFigure,
  PresenterImg,
  PresenterName,
  PresenterTitle,
  Tags,
  Tag,
  SateliteLabel,
} from './Presentation.style';
import satelliteIcon from '../../images/satellite.svg';

const Presentation = ({ data }) => {
  const [presentation] = data.allContentfulPresentation.edges;
  const content = presentation.node;
  const {
    title,
    image,
    date,
    tags,
    isSatelite,
    body,
    shortDescription,
    sponsors,
    presenter,
    slug,
    presentationLink,
    presentationEnd,
  } = content;

  const completed = presentationEnd ? isCompleted(presentationEnd) : false;
  const live = isLive(date);

  return (
    <Page>
      <SEO
        title={`${title}`}
        description={shortDescription}
        image={`https:${image.file.url}`}
        url={`https://virtualrefugeeconference.com/program/${slug}`}
      />
      <Share title={title} url={`https://virtualrefugeeconference.com/program/${slug}`} />
      <MainNavigation />
      <Container>
        <Content>
          <header>
            <HighlightHeading element="h2">{title}</HighlightHeading>
            <Date>{`${date} CET`}</Date>
            <Tags>
              {tags.map((tag, i) => (
                <Tag key={tag}>{`${tag} ${i + 1 < tags.length ? '•' : ''} `}</Tag>
              ))}
            </Tags>
          </header>
          <Intro>
            <Figure>
              {isSatelite && (
                <SateliteLabel>
                  <img src={satelliteIcon} />
                  Satellite Event
                </SateliteLabel>
              )}
              <Img alt={image.description} fluid={image.fluid} />
            </Figure>
            <MetaBox>
              {!completed && (
                <MetaBoxSection>
                  <Button to={presentationLink} title={live ? 'Join live stream' : 'Join stream'} />
                </MetaBoxSection>
              )}
              <MetaBoxSection>
                <MetaTitle>Speakers</MetaTitle>
                <PresenterList>
                  {presenter.map(singlePresenter => (
                    <PresenterItem key={singlePresenter.name}>
                      <PresenterFigure>
                        <PresenterImg alt={singlePresenter.image.description} fixed={singlePresenter.image.fixed} />
                      </PresenterFigure>
                      <div>
                        {singlePresenter.link ? (
                          <PresenterLink href={singlePresenter.link} target="_blank" rel="noopener noreferrer">
                            <PresenterName>{singlePresenter.name}</PresenterName>
                            {singlePresenter.title && <PresenterTitle>{singlePresenter.title}</PresenterTitle>}
                          </PresenterLink>
                        ) : (
                          <Fragment>
                            <PresenterName>{singlePresenter.name}</PresenterName>
                            {singlePresenter.title && <PresenterTitle>{singlePresenter.title}</PresenterTitle>}
                          </Fragment>
                        )}
                      </div>
                    </PresenterItem>
                  ))}
                </PresenterList>
              </MetaBoxSection>
            </MetaBox>
          </Intro>
          <Description>{documentToReactComponents(body.json)}</Description>
          {sponsors ? (
            <MetaBoxSection>
              <MetaTitle>Supported by</MetaTitle>
              <SponsorList>
                {sponsors.map(sponsor => (
                  <SponsorItem key={sponsor.fluid.src}>
                    {sponsor.fluid && <SponsorImg alt={sponsor.description} fluid={sponsor.fluid} />}
                  </SponsorItem>
                ))}
              </SponsorList>
            </MetaBoxSection>
          ) : null}
        </Content>
      </Container>
    </Page>
  );
};

export const pageQuery = graphql`
  query presentationQuery($slug: String!) {
    allContentfulPresentation(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          slug
          date(formatString: "DD.MM.YYYY, h:mm A")
          presentationEnd(formatString: "DD.MM.YYYY, h:mm A")
          title
          shortDescription
          tags
          isSatelite
          presentationLink
          presenter {
            name
            title
            link
            image {
              description
              fixed(width: 64, height: 64) {
                ...GatsbyContentfulFixed
              }
            }
          }
          sponsors {
            description
            fluid(maxWidth: 300) {
              src
              srcSet
              sizes
              base64
              aspectRatio
            }
          }
          image {
            description
            fluid(maxWidth: 960) {
              src
              srcSet
              sizes
              base64
              aspectRatio
            }
            file {
              url
            }
          }
          body {
            json
          }
        }
      }
    }
  }
`;

Presentation.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Presentation;
