import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Img from 'gatsby-image';
import { Heading } from '../../components';
import { rem } from '../../utils/utils';

const contentStyles = css`
  padding-top: 80px;
  text-align: left;
`;

const highlightHeadingStyles = ({
  theme: {
    font: { color },
  },
}) => css`
  color: ${color.brand};
  margin-bottom: 15px;
  font-size: ${rem(40)};
`;

const dateStyles = css`
  margin-top: 0;
  font-size: ${rem(24)};
  display: inline;
`;

const tagsStyles = css`
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
`;

const sateliteLabelStyles = ({ theme: { color } }) => css`
  position: absolute;
  color: ${color.white};
  z-index: 1;
  display: block;
  padding: 6px 9px;
  top: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.4);
  img {
    width: 14px;
    margin-right: 6px;
    position: relative;
    top: 1px;
  }
`;

const liveSateliteLabelStyles = ({ isLive, isCompleted }) =>
  isLive &&
  !isCompleted &&
  css`
    top: 65px;
  `;

const tagStyles = ({ theme: { color } }) => css`
  color: ${color.blueGray600};
`;

const introStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  margin: 40px 0;
  ${breakpoint.fromTablet} {
    display: flex;
  }
`;

const figureStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  margin: 0 0 40px 0;
  position: relative;
  ${breakpoint.fromTablet} {
    width: 60%;
  }
`;

const descriptionStyles = css`
  background: none;
`;

const metaBoxStyles = ({
  theme: {
    grid: { breakpoint },
    color,
  },
}) => css`
  background: ${color.white};
  padding: 20px;
  ${breakpoint.fromTablet} {
    margin: 0 0 0 30px;
    width: 40%;
  }
`;

const metaBoxSectionStyles = ({ theme: { color } }) => css`
  button {
    color: ${color.tealAccent400};
    border-color: ${color.tealAccent400};
    background: transparent;
    font-weight: 500;
    display: block;
    width: 100%;
  }
  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const metaTitleStyles = ({ theme: { color } }) => css`
  margin: 0 0 15px 0;
  color: ${color.blueGray600};
`;

const sponsorListStyles = css`
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

const sponsorItemStyles = css`
  display: inline;
  margin-right: 45px;
`;

const sponsorImageStyles = css`
  width: 90px;
  display: inline-block;
`;

const presenterListStyles = css`
  list-style-type: none;
  padding-left: 0;
  margin: 0;
`;

const presenterItemStyles = css`
  display: flex;
  align-items: center;
  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
`;

const presenterLinkStyles = ({
  theme: {
    font: { color },
  },
}) => css`
  text-align: left;
  color: ${color.dark};
  transition: 0.15s all ease-in-out;
  &:hover {
    color: ${color.brand};
  }
`;

const presenterFigureStyles = css`
  margin: 0;
  width: 64px;
  height: 64px;
  margin-right: 10px;
`;

const presenterImageStyles = css`
  border-radius: 100%;
  width: 64px;
  height: 64px;
`;

const presenterNameStyles = css`
  font-weight: bold;
  display: block;
`;

const presenterTitleStyles = ({ theme: { color } }) => css`
  color: ${color.blueGray600};
  display: block;
  font-size: ${rem(14)};
`;

/**
 * Component style declarations
 */
export const Content = styled('section')(contentStyles);
export const HighlightHeading = styled(Heading)(highlightHeadingStyles);
export const SateliteLabel = styled('span')(sateliteLabelStyles, liveSateliteLabelStyles);
export const Tags = styled('ul')(tagsStyles);
export const Tag = styled('span')(tagStyles);
export const Date = styled('h3')(dateStyles);
export const Intro = styled('div')(introStyles);
export const Figure = styled('figure')(figureStyles);
export const Description = styled('div')(descriptionStyles);
export const MetaBox = styled('div')(metaBoxStyles);
export const MetaBoxSection = styled('div')(metaBoxSectionStyles);
export const MetaTitle = styled('h4')(metaTitleStyles);
export const SponsorList = styled('ul')(sponsorListStyles);
export const SponsorItem = styled('li')(sponsorItemStyles);
export const SponsorImg = styled(Img)(sponsorImageStyles);
export const PresenterList = styled('ul')(presenterListStyles);
export const PresenterItem = styled('li')(presenterItemStyles);
export const PresenterLink = styled('a')(presenterLinkStyles);
export const PresenterFigure = styled('figure')(presenterFigureStyles);
export const PresenterImg = styled(Img)(presenterImageStyles);
export const PresenterName = styled('span')(presenterNameStyles);
export const PresenterTitle = styled('span')(presenterTitleStyles);
